<template>
    <div v-frag>
        <!-- <div class="">
            <div class="featured-header links visible-xs">
                <ul class="list-inline">
                    <li><router-link to="/" tag="span">{{ $t('betslip-select.home') }}</router-link></li> | 
                    <li><router-link tag="span" to="/mega-jackpot">{{ $t('betslip-select.mega-jackpot') }}</router-link></li> |
                    <li><router-link to="/casino-home" tag="span">{{ $t('casino.casino') }}</router-link></li> |
                    <li><router-link to="/virtuals" tag="span">{{ $t('casino.virtuals') }}</router-link></li> |
                    <li><router-link to = "/casino-live"  tag="span">{{ $t('casino.live_casino') }} </router-link></li>
                </ul>
            </div>
        </div>  -->
        <div class="iframe_live">
            <embed :src="url" class="iframe_live">
        </div>
    </div>
</template>

<script>
    import countryToCurrency from "country-to-currency";
    import { mapGetters } from "vuex";
    import { CASINO} from "@/config/casino_confs";
    export default {
        data(){
            return {
                url:""
            }
        },
        computed: {
            ...mapGetters({
                geo:'geo',
                user: 'user',
            }),
            currency() {
                if(this.user) {
                    return (this.user.iso == null) ? 'USD' : countryToCurrency[this.user.iso]
                } else {
                    return (this.geo == null) ? 'USD' : countryToCurrency[this.geo?.country_code2]
                }
            },
        },
        async mounted(){
            const gameId = this.$route.params.gameId
            const data = await CASINO.launchGameL(gameId,this.currency.toUpperCase(),this.$i18n.locale,this.geo?.country_code2,'REAL');
            this.url = data.gameURL;
        }
    }
</script>

<style>
    .iframe_live{
        background: #000;
        border: none;
        width: 100%;
        min-height: 85vh;
    }
</style>